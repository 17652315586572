import {
    Image,
    Group,
    Title,
    Text,
    createStyles,
    Badge,
    Flex,
    Stack,
} from "@mantine/core";
import { ItemTag } from "components/ItemTag/ItemTag";
import {
    getItemTagStatus,
    getFriendlyFulfillmentMethodName,
    getVariationWithLowestPrice,
} from "helpers/item/itemHelpers";
import { formatCentsToPrice } from "helpers/currency/currencyHelpers";
import { Item, ItemTagStatus, ItemType } from "types";
import { itemHelpers } from "helpers";
import { useMediaQuery } from "@mantine/hooks";

interface ListItemProps {
    item: Item;
}

const useStyles = createStyles((theme) => ({
    imageContainer: {
        position: "relative",
        textAlign: "center",
        "&:hover": {
            boxShadow: "0 8px 10px rgba(110, 116, 134, 0.24)",
        },
        borderRadius: "10px",
    },
    topLeft: {
        position: "absolute",
        top: theme.spacing.md,
        left: theme.spacing.md,

        [theme.fn.smallerThan("sm")]: {
            top: theme.spacing.sm,
            left: theme.spacing.sm,
        },
    },
    listItemContainer: {
        minWidth: 358,
        maxWidth: 379.5,
    },
    blackAndWhiteImage: {
        filter: "grayscale(100%)",
    },
    contentContainer: {
        [theme.fn.smallerThan("sm")]: {
            height: 120,
        },
    },
    title: {
        [theme.fn.smallerThan("sm")]: {
            fontSize: 12,
        },
    },
    price: {
        [theme.fn.smallerThan("sm")]: {
            fontSize: 14,
        },
    },
}));

export function ListItem({ item }: ListItemProps) {
    const { classes, theme } = useStyles();
    const smallScreen = useMediaQuery(
        `(max-width: ${theme.breakpoints.sm})`,
        false
    );
    const displayVariation = getVariationWithLowestPrice(item.variations);

    return (
        <Flex
            direction={smallScreen ? "row" : "column"}
            className={classes.listItemContainer}
            gap="md"
        >
            <div className={classes.imageContainer}>
                <Image
                    radius={10}
                    src={item.imageUrl}
                    alt={item.title}
                    height={smallScreen ? 120 : 253}
                    width={smallScreen ? 180 : "100%"}
                    className={
                        itemHelpers.isNoLongerOnSale(item)
                            ? classes.blackAndWhiteImage
                            : ""
                    }
                />
                <ItemTag
                    className={classes.topLeft}
                    size={smallScreen ? "sm" : "lg"}
                    status={getItemTagStatus(item)}
                />
            </div>
            <Stack className={classes.contentContainer}>
                <Flex
                    direction={smallScreen ? "column" : "row"}
                    justify="space-between"
                    gap="sm"
                >
                    <Stack spacing={smallScreen ? 0 : "sm"}>
                        <Title className={classes.title} order={5}>
                            {item.title}
                        </Title>
                        <Text size={smallScreen ? "xs" : "sm"} weight={300}>
                            {item.subtitle}
                        </Text>
                    </Stack>
                    <Title className={classes.price} order={3}>
                        {formatCentsToPrice({
                            cents: displayVariation.price,
                            currencyCode: item.currencyCode,
                        })}
                        {item.variations.length != 1 ? "+" : ""}
                    </Title>
                </Flex>
                <Group spacing="sm">
                    {item.fulfillmentTypes.map((type) => (
                        <Badge
                            key={type}
                            color="success"
                            variant="light"
                            radius={5}
                            size={smallScreen ? "sm" : "lg"}
                        >
                            {getFriendlyFulfillmentMethodName(item.type, type)}
                        </Badge>
                    ))}
                </Group>
            </Stack>
        </Flex>
    );
}
