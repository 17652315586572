import { Group, Text, SelectItemProps, Stack } from "@mantine/core";
import { forwardRef } from "react";
import { Address } from "types";

interface ItemProps extends SelectItemProps {
    address: Address;
    label: string;
    name: string;
}
const LocationSelectItem = forwardRef<HTMLDivElement, ItemProps>(
    function LocationSelectItem(
        { address, label, value, name, ...others }: ItemProps,
        ref
    ) {
        return (
            <div ref={ref} {...others}>
                <Stack spacing={0}>
                    <Text weight={600} size="md">
                        {label}
                    </Text>
                    <Text size="sm" weight={300} transform="uppercase">
                        {address.city}, {address.state}
                    </Text>
                </Stack>
            </div>
        );
    }
);

export default LocationSelectItem;
