import { getMerchantItems } from "api/merchants";
import { useQuery } from "@tanstack/react-query";

const awaitMerchantItems = async (merchantIdentifier: string) => {
    const { data } = await getMerchantItems(merchantIdentifier);
    return data;
};

export default function useMerchantItems(merchantIdentifier: string) {
    return useQuery(
        ["getMerchantItems", merchantIdentifier],
        () => awaitMerchantItems(merchantIdentifier),
        { staleTime: 0 }
    );
}
