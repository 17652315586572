import { Title, Group, Text, createStyles, Stack } from "@mantine/core";
import {
    AppType,
    DownloadAppButton,
} from "components/DownloadAppButton/DownloadAppButton";

interface EmptyItemsStateProps {
    name: string;
    message: string;
}

const useStyles = createStyles((theme) => ({
    emptyContainer: {
        marginTop: 160,
        paddingLeft: 32,
        paddingRight: 32,
        textAlign: "center",
        [theme.fn.smallerThan("md")]: {
            marginTop: 80,
            "& h2": {
                fontSize: 28,
            },
        },
    },
}));

export default function EmptyItemsState({
    name,
    message,
}: EmptyItemsStateProps) {
    const { classes } = useStyles();
    return (
        <Stack className={classes.emptyContainer} align="center" spacing="sm">
            <Title order={2}>{message}</Title>
            <Text weight={600}>Don&apos;t miss updates from {name}</Text>
            <Text weight={300}>
                Download the Oznr app and subscribe to receive push
                notifications
            </Text>
            <Group spacing={0}>
                <DownloadAppButton appType={AppType.ANDROID} />
                <DownloadAppButton appType={AppType.IOS} />
            </Group>
        </Stack>
    );
}
