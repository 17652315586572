import {
    Box,
    createStyles,
    Divider,
    Group,
    Image,
    Loader,
    ScrollArea,
    Stack,
    Text,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { trackEvent } from "analytics";
import { merchants } from "api";
import EmptyItemsState from "components/EmptyItemsState/EmptyItemsState";
import { Layout } from "components/Layout";
import { ListItem } from "components/ListItem/ListItem";
import { MerchantLocationDisplay } from "components/MerchantLocationDisplay/MerchantLocationDisplay";
import { SelectLocationModal } from "components/Modal/SelectLocationModal";
import { CartContext } from "context/cartContext";
import { sortItemCategories, sortItems } from "helpers/item/itemHelpers";
import {
    getMerchantLocationPath,
    getMerchantPath,
} from "helpers/url/urlHelpers";
import useBusinessDetails from "hooks/useBusinessDetails";
import useMerchantItems from "hooks/useMerchantItems";
import { isEmpty } from "lodash-es";
import type {
    GetStaticPaths,
    GetStaticProps,
    GetStaticPropsContext,
    NextPage,
} from "next";
import Head from "next/head";
import Link from "next/link";
import { useRouter } from "next/router";
import { useContext, useEffect, useRef, useState } from "react";
import { Item, ItemType, Merchant } from "types";
import { AnalyticsEvent } from "types/Analytics";

export const getStaticProps: GetStaticProps = async (
    context: GetStaticPropsContext
) => {
    let merchantInfo = null;
    let merchant = null;
    const merchantIdentifier = context.params?.merchantLocation as string;
    try {
        merchantInfo = await merchants.fetchMerchant(merchantIdentifier);
        merchant = merchantInfo.data;
    } catch (err) {
        // let next.js handle the error and render the default 404 or 500 page
    }

    return {
        props: {
            merchant,
        },
        notFound: !merchant,
        revalidate: 300, // 5 minutes
    };
};

export const getStaticPaths: GetStaticPaths = async () => {
    const subdomainResponse =
        await merchants.getMerchantLocationAndBusinessSubdomain();
    const subdomains = subdomainResponse.data;

    const paths = subdomains.map((domain) => ({
        params: {
            merchant: domain.businessSubdomain,
            merchantLocation: domain.merchantLocationSubdomain,
        },
    }));

    return { paths, fallback: true };
};

interface MerchantStoreProps {
    merchant: Merchant;
}

const useStyles = createStyles((theme) => ({
    bannerImage: {
        paddingTop: 60,
    },
    main: {
        maxWidth: 1350,
        margin: "auto",
        [theme.fn.smallerThan("sm")]: {
            maxWidth: "auto",
        },
    },
    gridBox: {
        marginTop: 48,
        rowGap: 60,
        columnGap: 60,
        display: "grid",
        justifyContent: "center",
        gridTemplateColumns: "repeat(auto-fill, 400px)",
        [theme.fn.smallerThan("sm")]: {
            marginTop: 24,
            rowGap: 32,
            columnGap: 24,
            gridTemplateColumns: "repeat(auto-fill, 350px)",
        },
    },
    gridHeader: {
        gridColumn: "1 / -1",
    },
    categoryNavContainer: {
        position: "sticky",
        top: 75,
        zIndex: 200,
        background: "white",
    },
    categoryNav: {
        paddingTop: "16, 0",
        [theme.fn.smallerThan("sm")]: {
            paddingTop: "4, 0",
        },
    },
    scrollArea: {
        margin: "auto",
        paddingLeft: 24,
        overflowX: "scroll",
        [theme.fn.smallerThan("md")]: {
            width: "auto",
            paddingLeft: 16,
        },
    },
    noTextWrap: {
        whiteSpace: "nowrap",
    },
    emptyContainer: {
        marginTop: 160,
        paddingLeft: 32,
        paddingRight: 32,
        textAlign: "center",
        [theme.fn.smallerThan("md")]: {
            marginTop: 80,
            "& h2": {
                fontSize: 28,
            },
        },
    },
    activeCategory: {
        fontWeight: 600,
        textDecoration: "underline",
        textUnderlineOffset: 10,
    },
}));

const MerchantStore: NextPage<MerchantStoreProps> = ({
    merchant,
}: MerchantStoreProps) => {
    const [modalOpened, setModalOpened] = useState<string>("");
    const router = useRouter();
    const { status, data, error, isFetching } = useMerchantItems(
        merchant?.subdomain
    );
    const [isTracked, setIsTracked] = useState(false);
    const { data: businessDetails } = useBusinessDetails(
        router.query.merchant as string
    );
    const { classes, cx, theme } = useStyles();
    const cartContext = useContext(CartContext);
    const smallScreen = useMediaQuery(
        `(max-width: ${theme.breakpoints.sm})`,
        true
    );
    const categoriesRef = useRef<Array<HTMLDivElement | null>>([]);
    const [visibleSection, setVisibleSection] = useState("");

    useEffect(() => {
        if (data && !isTracked) {
            setIsTracked(true);
            trackEvent(AnalyticsEvent.ViewItemList, {
                merchant_id: merchant.id,
                merchant_name: merchant.name,
                item_count: data.items.length,
                ...(cartContext?.cart[merchant.id]?.fulfillmentType && {
                    fulfillment_type:
                        cartContext?.cart[merchant.id]?.fulfillmentType,
                }),
            });
        }
    }, [data]);

    useEffect(() => {
        const handleScroll = () => {
            if (categoriesRef) {
                const scrollPosition = window.scrollY + 150;
                const currentCategorySection = categoriesRef.current.find(
                    (ref) => {
                        if (ref) {
                            const { offsetBottom, offsetTop } =
                                getDimensions(ref);
                            return (
                                scrollPosition > offsetTop &&
                                scrollPosition < offsetBottom
                            );
                        }
                    }
                );

                if (
                    currentCategorySection &&
                    currentCategorySection.id !== visibleSection
                ) {
                    setVisibleSection(currentCategorySection.id);
                } else if (!currentCategorySection && visibleSection) {
                    setVisibleSection("");
                }
            }
        };
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [visibleSection]);

    const getDimensions = (element: HTMLDivElement) => {
        const { height } = element.getBoundingClientRect();
        const offsetTop = element.offsetTop;
        const offsetBottom = offsetTop + height;

        return {
            height,
            offsetTop,
            offsetBottom,
        };
    };

    const scrollToCategory = (index: number) => {
        const categoryRef = categoriesRef.current[index];
        if (categoryRef) {
            categoryRef.scrollIntoView({
                behavior: "smooth",
            });
        }
    };

    function getItemUrl(item: Item) {
        const merchantPath = getMerchantLocationPath(
            router.query.merchant as string,
            merchant.subdomain
        );

        if (item.type === ItemType.MEMBERSHIP && !item.isLottery) {
            return `${merchantPath}/memberships/${item.id}`;
        } else {
            return `${merchantPath}/item/${item.id}`;
        }
    }

    const categorizedData = data?.items
        ?.sort(sortItemCategories)
        .reduce<{ [index: string]: Item[] }>((group, item) => {
            const { category } = item;
            if (category) {
                group[category.name] = group[category.name] || [];
                group[category.name].push(item);
            } else {
                group["Uncategorized"] = group["Uncategorized"] || [];
                group["Uncategorized"].push(item);
            }

            return group;
        }, {});

    function listItems() {
        if (isFetching && isEmpty(data)) {
            return (
                <Stack
                    className={classes.emptyContainer}
                    align="center"
                    spacing="sm"
                >
                    <Loader size="lg" />
                </Stack>
            );
        }

        if (isEmpty(data)) {
            return (
                <EmptyItemsState
                    name={merchant.name}
                    message="There are no items available right now"
                />
            );
        }

        return (
            categorizedData &&
            Object.keys(categorizedData).map((key, index) => {
                return (
                    <div
                        key={key}
                        id={key}
                        style={{
                            scrollMargin: smallScreen
                                ? 115
                                : index === 0
                                ? 145
                                : 90,
                        }}
                        ref={(el) => (categoriesRef.current[index] = el)}
                    >
                        <div className={classes.gridBox}>
                            {key !== "Uncategorized" && (
                                <Text
                                    fz={smallScreen ? theme.fontSizes.xl : 36}
                                    weight={400}
                                    pt={
                                        smallScreen
                                            ? "lg"
                                            : index === 0
                                            ? 0
                                            : 64
                                    }
                                    className={classes.gridHeader}
                                >
                                    {key}
                                </Text>
                            )}
                            {categorizedData[key]
                                ?.sort(sortItems)
                                .map((item) => (
                                    <div key={item.id}>
                                        <Link href={getItemUrl(item)} passHref>
                                            <div className="pointerCursor">
                                                <ListItem item={item} />
                                            </div>
                                        </Link>
                                    </div>
                                ))}
                        </div>
                    </div>
                );
            })
        );
    }

    if (router.isFallback) {
        // TODO update this with fancy loading design
        return <></>;
    }

    return (
        <>
            <Head>
                <title>{merchant.name}&apos; Shop</title>
                <meta
                    property="og:title"
                    content={`${merchant.name}'s shop!`}
                />
                <meta
                    property="og:description"
                    content={`Shop new releases, merch, and events from ${merchant.name}`}
                />
                <meta property="og:type" content="website" />
                <meta
                    property="og:url"
                    content={`${getMerchantPath(
                        router.query.merchant as string
                    )}`}
                />
                <meta property="og:image" content={merchant.darkLogoUrl} />
            </Head>
            <Layout merchant={merchant}>
                <Image
                    className={classes.bannerImage}
                    src={merchant.bannerUrl}
                    height={smallScreen ? 240 : 330}
                    alt={`${merchant.name} banner image`}
                />
                {data?.categories && data.categories.length > 0 && (
                    <div className={classes.categoryNavContainer}>
                        <Group
                            className={classes.scrollArea}
                            noWrap
                            spacing="xl"
                            py={smallScreen ? theme.spacing.md : 20}
                        >
                            {data.categories
                                .sort(
                                    (a, b) => a?.displayOrder - b?.displayOrder
                                )
                                .map((category, index) => {
                                    return (
                                        <Text
                                            key={category.name}
                                            c={theme.other.monoColors.gray_8}
                                            weight={400}
                                            fz={smallScreen ? "lg" : "xl"}
                                            onClick={() =>
                                                scrollToCategory(index)
                                            }
                                            className={cx(
                                                "pointerCursor",
                                                classes.noTextWrap,
                                                category.name ===
                                                    visibleSection &&
                                                    classes.activeCategory
                                            )}
                                        >
                                            {category.name}
                                        </Text>
                                    );
                                })}
                        </Group>
                        <Divider />
                    </div>
                )}
                <div className={classes.main}>
                    {smallScreen && (
                        <MerchantLocationDisplay merchant={merchant} />
                    )}
                    {listItems()}
                </div>
                {businessDetails?.data && (
                    <SelectLocationModal
                        logoUrl={merchant.darkLogoUrl}
                        opened={modalOpened === "selectLocationModal"}
                        onClose={() => setModalOpened("")}
                        withCloseButton={true}
                        locations={businessDetails.data.locations}
                        primaryColor={merchant.primaryColor}
                        titleText="Select a Location"
                    />
                )}
            </Layout>
        </>
    );
};

export default MerchantStore;
