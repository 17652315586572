import { createStyles, Title } from "@mantine/core";
import { faTicket } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { DefaultProps, ItemTagStatus } from "types";

export type TagSize = "sm" | "lg";

interface ItemTagProps extends DefaultProps {
    status: ItemTagStatus;
    size?: TagSize;
}

interface ItemTagStylesProps {
    size: TagSize;
}

const sizes = {
    sm: {
        height: 20,
        padding: "1px 4px",
        fontSize: 12,
        borderRadius: 4,
    },
    lg: {
        height: 32,
        padding: "6px 8px",
        fontSize: 18,
        borderRadius: 6,
    },
};

const useStyles = createStyles((theme, { size }: ItemTagStylesProps) => ({
    root: {
        ...sizes[size],
        width: "auto",
        overflow: "hidden",
        boxSizing: "border-box",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        letterSpacing: 1.5,
    },

    COMING_SOON: {
        backgroundColor: theme.colors.oznrPrimary[7],
        color: theme.other.monoColors.white,
    },

    NEW: {
        backgroundColor: theme.colors.success[6],
        color: theme.other.monoColors.white,
    },

    SOLD_OUT: {
        backgroundColor: theme.colors.failure[6],
        color: theme.other.monoColors.white,
    },

    CLOSED: {
        backgroundColor: theme.colors.gray[6],
        color: theme.other.monoColors.white,
    },

    MEMBERS_ONLY: {
        backgroundColor: theme.colors.oznrSecondary[6],
        color: theme.other.monoColors.white,
    },

    EARLY_ACCESS: {
        backgroundColor: theme.other.monoColors.lightBlue,
        color: "#434343",
    },

    RANDOM_DRAW: {
        backgroundColor: theme.other.monoColors.gold,
        color: theme.other.monoColors.white,
    },
}));

function friendlyStatus(status: ItemTagStatus): String {
    return status.replaceAll("_", " ");
}

export function ItemTag({ status, size = "lg", className }: ItemTagProps) {
    const { classes, cx } = useStyles({ size });
    const isRandomDraw = status === ItemTagStatus.RANDOM_DRAW;

    if (status == ItemTagStatus.NONE) {
        return <></>;
    }

    return (
        <Title
            className={cx(classes[status], classes.root, className)}
            order={5}
        >
            {isRandomDraw && (
                <span>
                    <FontAwesomeIcon
                        icon={faTicket}
                        style={{ marginRight: 5 }}
                    />
                </span>
            )}
            {friendlyStatus(status)}
        </Title>
    );
}
