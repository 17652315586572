import { faLocationDot } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Group, Stack, Text } from "@mantine/core";
import { addressHelpers } from "helpers";
import { useMerchantTheme } from "hooks/useMerchantTheme";
import { Merchant } from "types";

interface MerchantLocationDisplayProps {
    merchant: Merchant;
}

export function MerchantLocationDisplay({
    merchant,
}: MerchantLocationDisplayProps) {
    const theme = useMerchantTheme(merchant.primaryColor);
    return (
        <Group spacing="sm" pl="md" pt="sm">
            <FontAwesomeIcon
                icon={faLocationDot}
                color={theme.colors[theme.primaryColor][6]}
                size="1x"
                style={{
                    maxWidth: "16px",
                    maxHeight: "16px",
                }}
            />
            <Stack spacing={0}>
                <Text weight={600} size="md">
                    {merchant.displayName
                        ? merchant.displayName
                        : merchant.name}
                </Text>
                <Text size="sm" weight={300} transform="uppercase">
                    {addressHelpers.line1(merchant.address, true)}{" "}
                    {addressHelpers.line2(merchant.address)}
                </Text>
            </Stack>
        </Group>
    );
}
