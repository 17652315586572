import {
    Modal,
    Text,
    Image,
    Group,
    Title,
    Button,
    Select,
    MantineProvider,
    Stack,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import LocationSelectItem from "components/LocationSelectItem/LocationSelectItem";
import { getMerchantLocationPath } from "helpers/url/urlHelpers";
import { useMerchantTheme } from "hooks/useMerchantTheme";
import { useRouter } from "next/router";
import { useState } from "react";
import { Location } from "types/Merchant";

interface SelectLocationModalProps {
    logoUrl: string;
    opened: boolean;
    onClose: () => void;
    withCloseButton: boolean;
    locations: Location[];
    primaryColor: string;
    titleText?: string;
}

export function SelectLocationModal({
    logoUrl,
    opened,
    onClose,
    withCloseButton,
    locations,
    primaryColor,
    titleText = "Welcome to our store",
}: SelectLocationModalProps) {
    const [value, setValue] = useState(locations[0].subdomain);
    const merchantTheme = useMerchantTheme(primaryColor);
    const router = useRouter();
    const smallScreen = useMediaQuery(
        `(max-width: ${merchantTheme.breakpoints.sm})`,
        false
    );

    const data = locations.map((location) => ({
        ...location,
        value: location.subdomain,
        label: location.displayName ? location.displayName : location.name,
    }));

    function handleContinue() {
        router.push(
            `${getMerchantLocationPath(router.query.merchant as string, value)}`
        );
        onClose();
    }

    return (
        <MantineProvider theme={merchantTheme}>
            <Modal
                opened={opened}
                onClose={onClose}
                centered
                padding="lg"
                radius="md"
                trapFocus={false}
                withCloseButton={withCloseButton}
            >
                <Stack align="center">
                    <Image
                        src={logoUrl}
                        fit="contain"
                        alt="Merchant logo image"
                        height={60}
                        width={90}
                    />
                    <Title order={3}>{titleText}</Title>
                    <Text weight={300} align="center">
                        Choose the location you want <br /> to shop from today
                    </Text>
                    <Select
                        itemComponent={LocationSelectItem}
                        size="lg"
                        sx={{ width: "100%" }}
                        placeholder="Choose a Location"
                        data={data}
                        value={value}
                        onChange={(e) => setValue(e ? e : "")}
                    />
                    <Button
                        radius="xl"
                        size="lg"
                        color="primary"
                        onClick={handleContinue}
                        fullWidth={smallScreen}
                        disabled={value === ""}
                    >
                        Continue
                    </Button>
                </Stack>
            </Modal>
        </MantineProvider>
    );
}
